import BR from 'primitives/Layout/BR';
import CatalogImage from 'components/MediaServerImage/CatalogImage';
import DummyTiles from '../DummyTiles';
import EmptyState from '../EmptyState';
import InfiniteScroll from 'react-infinite-scroller';
import logger, { CONTEXTS } from 'modules/Logger';
import NavLink from 'components/NavLink';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PlayButtonContainerPrimitive from 'components/Artist/PlayButtonContainer';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import Row from '../Row';
import SectionHeaderText from '../SectionHeaderText';
import ShouldShow from 'components/ShouldShow';
import Tile from 'components/Tile/Tile';
import Tiles from 'components/Tiles/Tiles';
import TilesImageWrapper from 'components/Tile/primitives/TilesImageWrapper';
import transport from 'api/transport';
import useMount from 'hooks/useMount';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Artist } from 'state/Artists/types';
import { Artists as ArtistsIcon } from 'styles/icons';
import { Fragment, useCallback, useState } from 'react';
import { getMyMusicArtists } from 'state/MyMusic/services';
import { H4, TilesWrapper } from '../primitives';
import { slugify } from 'utils/string';
import { STATION_TYPE } from 'constants/stationTypes';
import { TILE_RES } from 'components/MediaServerImage';
import { TILES_IN_ROW } from '../constants';
import { uniqBy } from 'lodash-es';
import type { FunctionComponent } from 'react';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

export type Props = {
  artistReceived: (artists: Array<Artist>) => void;
  profileId: number | null;
  sessionId: string | null;
};

type ArtistsArray = Array<
  {
    count?: number;
  } & Artist
>;

const Artists: FunctionComponent<Props> = ({
  artistReceived,
  profileId,
  sessionId,
}) => {
  const [artists, setArtists] = useState<ArtistsArray>([]);
  const [hasReceivedArtists, setHasReceivedArtists] = useState<boolean>(false);
  const [nextPageKey, setNextPageKey] = useState<string | null | undefined>(
    undefined,
  );
  const translate = useTranslate();
  const hasMore = !hasReceivedArtists || (hasReceivedArtists && !!nextPageKey);

  const loadMore = useCallback(async () => {
    if (!hasMore) return;

    let artistsData: ArtistsArray = [];

    try {
      const res = await transport(
        getMyMusicArtists({
          key: nextPageKey,
          limit: 30,
          profileId,
          sessionId,
        }),
      );
      artistsData = res?.data?.data ?? [];

      artistReceived(artistsData);
      const combinedArtists = [...artists, ...artistsData];
      const uniqueArtists = uniqBy(combinedArtists, 'id');

      setArtists(uniqueArtists);
      setHasReceivedArtists(true);
      setNextPageKey(res?.data?.links?.nextPageKey);
    } catch (e: any) {
      const errObj = e instanceof Error ? e : new Error(e);
      logger.error(CONTEXTS.YOUR_LIBRARY, errObj.message, {}, errObj);
    }
  }, [artistReceived, artists, hasMore, nextPageKey, profileId, sessionId]);

  useMount(() => {
    loadMore();
  });

  let tiles = [...DummyTiles({ allRoundTiles: true })];

  if (hasReceivedArtists) {
    tiles = artists.map(({ count, id, name, playedFrom }) => {
      const artistUrl = `/your-library/artists/${slugify(name)}-${id}/`;

      const subtitle = translate('{count} song{plural}', {
        count: `${count || 0} `,
        plural: count === 1 ? '' : 's',
      });

      const playButtonComponent = (
        <PlayButtonContainerPrimitive>
          <PlayButton
            additionalOpts={{ myMusicType: STATION_TYPE.ARTIST }}
            className="play"
            myMusicType={STATION_TYPE.ARTIST}
            playedFrom={playedFrom || PLAYED_FROM.PROF_MY_ARTISTS_ARTISTS}
            seedId={id}
            stationId={id}
            stationType={STATION_TYPE.MY_MUSIC}
          />
        </PlayButtonContainerPrimitive>
      );

      const imageComponent = (
        <TilesImageWrapper>
          <CatalogImage
            alt={name}
            aspectRatio={1}
            background
            height={TILE_RES}
            id={id}
            type={STATION_TYPE.ARTIST}
            width={TILE_RES}
          />
        </TilesImageWrapper>
      );

      return (
        <Fragment key={`${id}-${name}-fragment`}>
          <Row
            imageComponent={imageComponent}
            isRoundImage
            playButtonComponent={playButtonComponent}
            subtitleComponent={subtitle}
            title={name}
            url={artistUrl}
          />
          <Tile
            isRoundImage
            key={`${id}-${name}-tile`}
            noTileOnMobile
            subTitle={subtitle}
            tileDelay={0}
            tilesInRow={TILES_IN_ROW}
            title={name}
            url={artistUrl}
          >
            {playButtonComponent}
            {imageComponent}
          </Tile>
        </Fragment>
      );
    });
  }

  return (
    <>
      <H4
        data-test="your-library-artists-title"
        hideHeader={tiles.length === 0}
      >
        <SectionHeaderText>{translate('Artists')} (A-Z)</SectionHeaderText>
      </H4>

      <ShouldShow shouldShow={tiles.length > 0 || !hasReceivedArtists}>
        <BR />
        <TilesWrapper>
          <InfiniteScroll hasMore={hasMore} loadMore={loadMore} pageStart={0}>
            <Tiles noTileOnMobile tilesInRow={TILES_IN_ROW}>
              {tiles}
            </Tiles>
          </InfiniteScroll>
        </TilesWrapper>
      </ShouldShow>

      <ShouldShow shouldShow={tiles.length === 0}>
        <EmptyState
          buttons={[
            <NavLink
              dataTest="artists-empty-link"
              key="artists-empty-link"
              to="/artist/"
            >
              <OutlinedButton>{translate('Browse Artists')}</OutlinedButton>
            </NavLink>,
          ]}
          icon={ArtistsIcon}
          subtitle={translate('Need to find an artist you love?')}
          title={translate('Any artists you follow will show up here')}
        />
      </ShouldShow>
    </>
  );
};

export default Artists;
